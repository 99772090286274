<template>
  <!-- 操作记录 -->
  <el-row>
    <el-col>
      <el-card class="box-card">
        <template #header>
          <div class="card-header">
            <span class="SubHead">操作记录</span>
          </div>
        </template>
        <el-form ref="operateLogFormRef" :inline="true" :model="operateLogForm" style="padding-top: 15px">
          <el-row justify="space-between">
            <el-col :span="20">
              <el-form-item label="选择日期" prop="timeRange">
                <el-date-picker v-model="operateLogForm.timeRange" type="daterange" size="small"
                  start-placeholder="开始日期" end-placeholder="结束日期" value-format="X"></el-date-picker>
              </el-form-item>
              <el-form-item label="操作类型" prop="type">
                <el-select v-model="operateLogForm.type" placeholder="请选择操作类型" size="small">
                  <el-option label="BM绑定" value="1"></el-option>
                  <el-option label="BM解绑" value="2"></el-option>
                  <el-option label="余额转移" value="3"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="广告账户ID" prop="id">
                <el-input v-model="operateLogForm.account_id" size="small" placeholder="请输入账户ID"
                  style="width: 200px;"></el-input>
              </el-form-item>
              <el-form-item style="margin-left: 14px">
                <el-button type="primary" @click="queryFormBtn" size="small">查询</el-button>
                <el-button @click="resetFormBtn" size="small">重置</el-button>
              </el-form-item>
            </el-col>
            <el-col :span="4" style="text-align: right">
              <el-button type="text" class="LinkText" @click="downLoadData">下载</el-button>
            </el-col>
          </el-row>
        </el-form>

        <el-table :data="operateLogList" style="width: 100%" :header-cell-style="{
          background: 'var(--el-table-row-hover-background-color)',
        }">
          <el-table-column property="data" label="操作记录" width="500px" />
          <el-table-column property="l_type" label="操作类型" />
          <el-table-column property="msg" label="状态" />
          <!-- <el-table-column property="name" label="操作人" /> -->
          <el-table-column property="date" label="操作时间" />
          <el-table-column property="operator" label="操作人" />
          <template #empty>
            <img src="@/assets/empty.gif" alt="" style="width: 400px; height: 400px" />
          </template>
        </el-table>
        <!-- 分页器 -->
        <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
          :current-page="queryInfo.page" :page-sizes="[5, 10]" :page-size="queryInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper" :total="total" background>
        </el-pagination>
      </el-card>
    </el-col>
  </el-row>

  <!-- 下载操作记录弹窗 -->
  <el-dialog v-model="downLoadDialogVisible" title="下载操作记录" width="790px">
    <!-- 内容主体区域 -->
    <el-form ref="downLoadFormRef" label-width="100px">
      <el-form-item label="选择时间" class="timeRange">
        <el-date-picker v-model="operateTimeRange" type="daterange" size="small" start-placeholder="开始日期"
          end-placeholder="结束日期" value-format="X"></el-date-picker>
      </el-form-item>
      <el-form-item label="操作类型">
        <el-select v-model="operateType" placeholder="请选择操作类型" size="small">
          <el-option label="BM绑定" value="1"></el-option>
          <el-option label="BM解绑" value="2"></el-option>
          <el-option label="余额转移" value="3"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="选择字段" class="downLoadForm">
        <el-transfer v-model="operateValue" style="text-align: left; display: inline-block"
          :left-default-checked="['l_type', 'msg', 'operator']" :right-default-checked="[]" :titles="['可选字段', '已选字段']"
          :button-texts="['移除', '添加']" :format="{
            noChecked: '${total}',
            hasChecked: '${checked}/${total}',
          }" :data="operateData">
        </el-transfer>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button size="small" @click="downLoadFormClose">取 消</el-button>
        <el-button size="small" type="primary" @click="downLoadFormBtn">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script>
import { ElLoading } from 'element-plus'
export default {
  data() {
    return {
      userInfo: '',
      // 表单查询
      queryInfo: {
        query: '',
        page: 1,
        pagesize: 10,
      },
      total: 0,
      operateLogForm: {
        timeRange: '',
        type: '',
        account_id: '',
      },
      operateLogList: [],
      downLoadDialogVisible: false,
      operateTimeRange: '',
      operateType: '',
      operateValue: ['data', 'name', 'date'],
      operateData: [
        {
          key: 'data',
          label: '操作记录',
          disabled: true,
        },
        {
          key: 'l_type',
          label: '操作类型',
        },
        {
          key: 'msg',
          label: '状态',
        },
        {
          key: 'operator',
          label: '操作人',
        },
        {
          key: 'date',
          label: '操作时间',
          disabled: true,
        },
      ],
    }
  },
  mounted() {
    this.userInfo = this.$store.getters.userInfo
    this.getOperateLogList()
  },
  methods: {
    queryFormBtn() {
      this.getOperateLogList()
    },
    resetFormBtn() {
      this.$refs.operateLogFormRef.resetFields()
      this.getOperateLogList()
    },
    async getOperateLogList() {
      const { data: res } = await this.$http.post('cloudfb/operationRecord', {
        page: this.queryInfo.page,
        pagesize: this.queryInfo.pagesize,
        timeRange: this.operateLogForm.timeRange,
        type: this.operateLogForm.type,
        account_id: this.operateLogForm.account_id,
      })
      if (res.code == 300) {
        this.$router.push('/login')
      }
      if (res.code == 100) {
        this.$message.error(`${res.msg}`)
        this.$router.push('/clientInfo')
      }
      this.operateLogList = res.data
      this.total = res.total
    },
    // 展示条数改变触发的函数
    handleSizeChange(newSize) {
      this.queryInfo.pagesize = newSize
      this.getOperateLogList()
    },
    handleCurrentChange(newPage) {
      this.queryInfo.page = newPage
      this.getOperateLogList()
    },
    downLoadData() {
      if (this.operateLogList == '') {
        return this.$message({
          showClose: true,
          message: '暂无数据',
          type: 'warning',
        })
      }
      this.downLoadDialogVisible = true
    },
    downLoadFormBtn() {
      var operateValue = this.operateValue.join(',')

      const loading = ElLoading.service({
        spinner: 'el-icon-loading',
        lock: true,
        text: '下载中，请稍后...',
      })

      this.$http({
        // 用axios发送post请求
        method: 'post',
        url: 'cloudfb/opera_export', // 请求地址
        data: {
          token: this.userInfo.token,
          timeRange: this.operateTimeRange,
          field: operateValue,
          type: this.operateType,
        },
        responseType: 'blob', // 表明返回服务器返回的数据类型
      })
        .then((res) => {
          // 处理返回的文件流
          const content = res.data
          const blob = new Blob([content])
          const fileName = '操作记录.xls'
          if ('download' in document.createElement('a')) {
            // 非IE下载
            const elink = document.createElement('a')
            elink.download = fileName
            elink.style.display = 'none'
            elink.href = URL.createObjectURL(blob)
            document.body.appendChild(elink)
            elink.click()
            URL.revokeObjectURL(elink.href) // 释放URL 对象
            document.body.removeChild(elink)
          } else {
            // IE10+下载
            navigator.msSaveBlob(blob, fileName)
          }
        })
        .finally(() => {
          loading.close()
        })
      this.downLoadDialogVisible = false
    },
    downLoadFormClose() {
      this.downLoadDialogVisible = false
    },
  },
}
</script>

<style lang="less" scoped>
.timeRange {
  :deep(.el-date-editor.el-input__inner) {
    width: 220px !important;
  }
}

.downLoadForm {
  .el-checkbox {
    width: 100px;
  }

  :deep(.el-transfer__button) {
    display: block;
    min-height: 32px;
    padding: 9px 15px;
    font-size: 12px;
  }

  :deep(.el-transfer__button:nth-child(2)) {
    margin-left: 0 !important;
  }
}
</style>
